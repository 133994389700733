import moment from "moment";
import Pikaday from "pikaday";

document.addEventListener("DOMContentLoaded",function(event){
  const default_format = "YYYY-MM-DD";

  document.querySelectorAll(".date-picker").forEach(function(element) {
    let minDate = element.dataset.minDate;
    let maxDate = element.dataset.maxDate;
    let format = element.dataset.format;

    format = format === undefined ? default_format : format;

    if (minDate !== undefined) {
      minDate = moment(minDate).utc().toDate();
    }

    if (maxDate !== undefined) {
      maxDate = moment(maxDate).utc().toDate();
    }

    let picker = new Pikaday({
      field:  element,
      format: format,
      minDate: minDate,
      maxDate: maxDate
    });
  });
});
