import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;
import '../../shared/helpers.js'

$.fn.collection = function(options) {
  var options = options || {};
  var $table = $(this);
  var $thead = $table.find('thead');
  var $tbody = $table.find('tbody');
  var $rows =  $tbody.find('tr').clone();
  $rows.each(function(index) {
    var $cells = $(this).find('td,th')
    var values = $cells.map(function() { return $(this).text().trim().toLowerCase(); }).toArray();
    $(this).attr("cell-values", JSON.stringify(values));
  });

  var $sortableColumns   = $thead.find('[data-sortable]');
  var $searchableColumns = $thead.find('[data-searchable]');
  var $filterableColumns = $thead.find('[data-filterable]');

  var filterableColumnIndex = $filterableColumns.first().index();
  var searchableColumnIndexes = $searchableColumns.map(function(index) { return $(this).index() }).toArray();

  // search input
  var $search = options.$search;

  // `conditions` is a mutable object that gets passed to the `apply()` function,
  // effectively passed through to `search()` and `sort()` functions
  var conditions = {
    sort: {
      column: 0,
      order: 1,
      fn: function(value) { return value }
    },

    filter: {
      column: filterableColumnIndex,
      value: ''
    },

    search: {
      columns: searchableColumnIndexes,
      value: ''
    }
  };

  //
  // bind events for sorting and searching.
  //
  // when an event is triggered, these functions mutate the conditions object,
  // clone the original rows, and pass the conditions and rows to `apply()`.
  // `apply()` doesn't perform any mutation; it simply returns a new set of rows
  // to be injected into the html
  //
  if ($search) {
    $search.keyup(debounce(function() {
      conditions.search.value = $(this).val();
      replace(function() { return apply($rows, conditions) });
    }, 500));
  }

  $sortableColumns.click(function(event) {
    event.preventDefault();

    conditions.sort.order *= -1;
    conditions.sort.column = $(this).index();
    conditions.sort.fn = window[$(this).data("sort-type")] || function(value) { return value };

    $sortableColumns.removeClass('sorted sorted-asc sorted-desc');
    $(this).addClass('sorted');
    $(this).addClass(conditions.sort.order > 0 ? 'sorted-asc' : 'sorted-desc');

    replace(function() { return apply($rows, conditions) });
  });

  // updates the html of the table with a new set of rows
  function replace(results) {
    $tbody.css({opacity: 0.35});
    $tbody.html(results());
    $tbody.animate({opacity: 1}, 100);
  }

  this._filter = function(value) {
    conditions.filter.value = value;
    replace(function() { return apply($rows, conditions) });
  }

  return this;
};

var cache = {};

function apply($rows, conditions) {
  var hash = hashConditions(conditions);
  var cached = cache[hash];
  if (cached) { return cached }

  // Parse and attach JSON once now
  $rows.each(function() {
    var cell_values = JSON.parse($(this).attr('cell-values') || "[]");
    $(this).data('cell-values', cell_values);
  });

  $rows = search($rows, conditions.search);
  $rows = filter($rows, conditions.filter);
  $rows = sort($rows, conditions.sort);
  cache[hash] = $rows;
  return $rows;
}

function search($rows, conditions) {
  var value = conditions.value.toLowerCase().replace(/[^0-9a-z]/ig, '');
  if (value == "") { return $rows; }

  return $rows.filter(function(index) {
    var cells = $(this).data('cell-values').filter(function(_, i) {
      return conditions.columns.indexOf(i) != -1
    });

    var content = cells.join("");

    return content.indexOf(value) !== -1;
  });
}

function filter($rows, conditions) {
  var value = conditions.value.toLowerCase();
  if (value == "") { return $rows; }

  var pattern = new RegExp(conditions.value.toLowerCase(), 'i');

  return $rows.filter(function() {
    var cell = $(this).data('cell-values').filter(function(_, i) {
      return i == conditions.column
    })[0];

    return cell.search(pattern) != -1;
  });
}

function sort($rows, conditions) {
  return $rows.slice().sort(function(a,b) {
    var a_values = $(a).data('cell-values');
    var b_values = $(b).data('cell-values');
    var a_value = a_values[conditions.column];
    var b_value = b_values[conditions.column];
    var a = conditions.fn(a_value);
    var b = conditions.fn(b_value);
    if (conditions.order == 1) {
      return a > b ? 1 : -1;
    }
    else {
      return a < b ? 1 : -1;
    }
  })
}

//http://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

function hashConditions(conditions) {
  var str = JSON.stringify(conditions);
  var hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
