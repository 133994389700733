window.Convert = {
  inchesToMeters: function(inches) { return inches * 0.0254  },
  metersToInches: function(meters) { return meters * 39.37007874015748 },
  gramsToKg:      function(grams)  { return grams / 1000 },
  gramsToTonnes:  function(grams)  { return grams / 1000000 },
  gramsToLb:      function(grams)  { return grams / 453.59237 },
  kgToGrams:      function(kg)     { return kg * 1000 },
  lbToGrams:      function(lb)     { return lb * 453.59237 }
}

window.Format = {
  numberWithCommas: function (n) {
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  },
  trees: function(n) {
    var precision = n < 100 ? 2 : 0;
    return Format.numberWithCommas(n.toFixed(precision));
  }
}

window.isInt = function isInt(n) {
  return typeof n === 'number' && parseFloat(n) === parseInt(n, 10) && !isNaN(n);
}

window.parseNumber = function parseNumber(n) {
  n = n.toString().replace(/[^\d|\.\-]/g, '');

  // is a float
  if (/\./.test(n)) {
    return parseFloat(n);
  }
  // is an int
  else {
    return parseInt(n);
  }
}

window.parsePeriod = function parsePeriod(str) {
  str = str || "";
  var strs = str.trim().split(" ");

  var months = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
  ];

  var month = months.indexOf(strs[0].toLowerCase());
  var year = strs[1];
  var value = (new Date(year, month)).getTime();
  return value;
}

window.parseDate = function parseDate(str) {
  str = (str || "").trim();
  var value = (new Date(Date.parse(str))).getTime();
  return value;
}

window.defined = function defined(obj) {
  return typeof obj != 'undefined';
}

