import _ from './shared/airbrake'
import './shared/application';

// Setup simtulus controllers
import { Application } from 'stimulus';
import DropdownMenuController from './controllers/dropdown_menu_controller';

window.Stimulus = Application.start()
Stimulus.register("dropdown-menu", DropdownMenuController)

// Social network sharing
window.Social = {};

Social.openUrl = function(url, width, height) {
  width = width || 640;
  height = height || 480;
  var left = (screen.width / 2) - (width / 2);
  var top = (screen.height * 0.3) - (height / 2);
  var opt = "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top + ",menubar=no,status=no,location=no";
  window.open(url, 'popup', opt);
  return false;
};

Social.share = function(el) {
  var $el   = $(el);
  var site  = $el.data('site');
  var title = encodeURIComponent($el.data('title') || '');
  var desc  = encodeURIComponent($el.data("desc")  || ' ');
  var image = encodeURIComponent($el.data("image") || '');
  var url   = encodeURIComponent($el.data("url")   || '');
  if (url.length == 0) {
    url = encodeURIComponent(location.href)
  }

  if (site == "facebook") {
    return Social.openUrl("http://www.facebook.com/sharer/sharer.php?u=" + url + "&display=popup&quote=" + desc + "", 555, 400);
  }

  if (site == "linkedin") {
    return Social.openUrl("https://www.linkedin.com/shareArticle?mini=true&url=" + url + "&title=" + title + "&summary=" + desc + "");
  }

  if (site == "twitter") {
    return Social.openUrl("https://twitter.com/intent/tweet?url=" + url + "&text=" + desc, 650, 300);
  }

  return false;
};
