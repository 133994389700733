import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;
import _ from "underscore";
import Backbone from "backbone";

import "./plugins/jquery.collection"
import "./plugins/bootstrap3_4";
import "./datepicker";

import Rails from '@rails/ujs';
Rails.start();

_.templateSettings = {
  interpolate: /\{\{\=(.+?)\}\}/g,
  evaluate: /\{\{(.+?)\}\}/g
};

Backbone.Model.prototype.toJSON = function() {
  var json = _.clone(this.attributes);
  for(var attr in json) {
    if((json[attr] instanceof Backbone.Model) || (json[attr] instanceof Backbone.Collection)) {
      json[attr] = json[attr].toJSON();
    }
  }
  return json;
};

function onDocumentLoaded () {

  const linksWithParams = document.querySelectorAll("[rel~='keep-params']");

  linksWithParams.forEach(link => {
    link.addEventListener("click", function(e){
      e.preventDefault();

      const href = link.getAttribute('href');
      const targetUrl = new URL(href, window.location);
      const newParams = Object.fromEntries(targetUrl.searchParams.entries());

      // Merge new params into current URL params and go
      const url = new URL(window.location);
      Object.keys(newParams).forEach(key => url.searchParams.set(key, newParams[key]));
      window.location.href = url;
    });
  });

  $(".tooltip").tooltip();
  $("[rel~='tooltip']").tooltip();

  $('table[data-collection]').each(function() {
    $(this).collection();
  });

  $(document).on("click", "[data-href]:not([data-href^='#'])", function(e){
    var href = $(this).data('href');
    var target = $(this).data('target');
    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      target = "_blank";
    }
    if (target) {
      window.open(href, target).focus();
    }
    else {
      window.location = href;
    }
  });

  $(document).on("click", ".alert .close", function(event){
    event.preventDefault();
    $(this).parents('.alert').slideUp(250);
  });

  $(document).on("click", ".flash .alert .close", function(event){
    event.preventDefault();
    $(this).parents('.alert').slideUp(250);
    $(this).parents('.flash').slideUp(250);
  });

  $('.js-trigger').each(function() {
    var $trigger = $(this);
    var $remote = $($trigger.data('trigger'));

    $trigger.click(function(event) {
      event.preventDefault();
      $remote.slideToggle(200);
      $trigger.toggleClass('active');
    });
  });

}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', onDocumentLoaded);
} else {
    onDocumentLoaded();
}
